import { Logger, ILogObj } from 'tslog'
import { logConfig } from '#shared/config/log'

export const logger: Logger<ILogObj> = new Logger({
	// type: 'pretty',
	name: 'client',
	...logConfig,
	// prettyLogTemplate: "{{yyyy}}.{{mm}}.{{dd}} {{hh}}:{{MM}}:{{ss}}:{{ms}} [{{name}}] {{logLevelName}} {{filePathWithLine}} ",
	// maskValuesOfKeys: ['password', 'secret'],
})
