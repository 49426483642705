'use client'
import { redirect, useRouter } from 'next/navigation'
import { TextInput, PasswordInput, Button } from '@dcbl/ui'
import { UserLoginArgsSchema } from '#shared/schemas/user'
import { UserLoginArgs, UserLoginPayload } from '#shared/types'
import { useForm } from '#client/hooks/useForm'

export function LoginForm() {
	const router = useRouter()
	const form = useForm<UserLoginArgs, UserLoginPayload>({
		schema: UserLoginArgsSchema,
		endpoint: 'user/login',
		defaultValues: {
			email: '',
			password: '',
		},
		onSubmissionResponse: (res) => {
			// console.debug('onSubmissionResponse', res)
			if (res.status === 'success') {
				if (res.result?.redirect) {
					router.replace(res.result?.redirect)
					// redirect(res.result.redirect)
				} else {
					router.replace('/')
					// redirect('/')
				}
			}
		},
	})

	return (
		<form.Root>
			<form.Field name="email" label="Email">
				{(props) => {
					return <TextInput {...props} autoComplete="username" />
				}}
			</form.Field>
			<form.Field name="password" label="Password">
				{(props) => {
					return <PasswordInput {...props} autoComplete="current-password" />
				}}
			</form.Field>
			<Button type="submit" loading={form.loading}>
				Login
			</Button>
		</form.Root>
	)
}
