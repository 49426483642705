import {
	FormValuesType,
	useForm as useDCBLForm,
	UseFormOptions as UseDCBLFormOptions,
	useToast,
} from '@dcbl/ui'
import { logger } from '#client/lib/log'
import { fetchAPI } from '#client/lib/fetchAPI'
import { APIResponseBody } from '#shared/types'

interface UseFormOptions<TValues extends FormValuesType, TResult>
	extends Omit<UseDCBLFormOptions<TValues>, 'submitValues'> {
	endpoint?: string
	extraValues?: Partial<TValues>
	submitValues?: UseDCBLFormOptions<TValues>['submitValues']
	onSubmissionResponse: (res: APIResponseBody<TResult>) => void
}

export function useForm<TValues extends FormValuesType, TResult = any>({
	endpoint,
	onSubmissionResponse,
	extraValues = {},
	submitValues,
	...options
}: UseFormOptions<TValues, TResult>) {
	const toast = useToast()
	return useDCBLForm<TValues>({
		submitValues: async (values) => {
			
			const mergedValues = {
				...values,
				...extraValues,
			}
			
			if (endpoint) {
				try {
					const res = await fetchAPI<APIResponseBody<TResult>>(endpoint, {
						...values,
						...extraValues,
					})
					if (onSubmissionResponse) {
						onSubmissionResponse(res)
					}
					return res
				} catch (err) {
					logger.error(err)
					toast.error('Communication with the server failed.')
					return {
						status: 'error',
						issues: [
							{
								message: 'Communication with the server failed.',
							},
						],
					}
				}
			} else if (submitValues) {
				const res = await submitValues(mergedValues)
				if (onSubmissionResponse) {
					onSubmissionResponse(res as APIResponseBody<TResult>)
				}
				return res
			}
			
			return {
				status: 'error',
				issues: [
					{
						message: 'Cannot submit.',
					},
				],
			}

		},
		...options,
	})
}
